// Generated by Framer (c563d2c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/X1dK_fpxD";

const enabledGestures = {YmvBf5PJG: {hover: true}};

const cycleOrder = ["YmvBf5PJG", "iPx6mhDCg"];

const serializationHash = "framer-WTZbK"

const variantClassNames = {iPx6mhDCg: "framer-v-1861i7c", YmvBf5PJG: "framer-v-1ksyfib"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Active: "iPx6mhDCg", Inactive: "YmvBf5PJG"}

const getProps = ({height, id, link, title, width, ...props}) => { return {...props, PpcFEFL2P: title ?? props.PpcFEFL2P ?? "Home", uWC2gSvUo: link ?? props.uWC2gSvUo, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "YmvBf5PJG"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, PpcFEFL2P, uWC2gSvUo, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "YmvBf5PJG", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = [sharedStyle.className]

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><Link href={uWC2gSvUo} nodeId={"YmvBf5PJG"}><motion.a {...restProps} {...gestureHandlers} className={`${cx(scopingClassNames, "framer-1ksyfib", className, classNames)} framer-1gkc30q`} data-framer-name={"Inactive"} layoutDependency={layoutDependency} layoutId={"YmvBf5PJG"} ref={ref ?? ref1} style={{opacity: 0.5, ...style}} variants={{"YmvBf5PJG-hover": {opacity: 0.8}, iPx6mhDCg: {opacity: 1}}} {...addPropertyOverrides({"YmvBf5PJG-hover": {"data-framer-name": undefined}, iPx6mhDCg: {"data-framer-name": "Active"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-13udwc7"} data-styles-preset={"X1dK_fpxD"}>Home</motion.p></React.Fragment>} className={"framer-nrvnih"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"T3awv3Qc0"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={PpcFEFL2P} variants={{"YmvBf5PJG-hover": {"--extracted-r6o4lv": "var(--token-2a92191a-5ab0-4861-b04e-0066e5484186, rgb(27, 27, 27))"}}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({"YmvBf5PJG-hover": {children: <React.Fragment><motion.p className={"framer-styles-preset-13udwc7"} data-styles-preset={"X1dK_fpxD"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-2a92191a-5ab0-4861-b04e-0066e5484186, rgb(27, 27, 27)))"}}>Home</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.a></Link></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-WTZbK.framer-1gkc30q, .framer-WTZbK .framer-1gkc30q { display: block; }", ".framer-WTZbK.framer-1ksyfib { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-WTZbK .framer-nrvnih { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-WTZbK.framer-1ksyfib { gap: 0px; } .framer-WTZbK.framer-1ksyfib > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-WTZbK.framer-1ksyfib > :first-child { margin-top: 0px; } .framer-WTZbK.framer-1ksyfib > :last-child { margin-bottom: 0px; } }", ".framer-WTZbK.framer-v-1861i7c.framer-1ksyfib { cursor: unset; }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 18
 * @framerIntrinsicWidth 37.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"iPx6mhDCg":{"layout":["auto","auto"]},"RL9Nlmym4":{"layout":["auto","auto"]}}}
 * @framerVariables {"PpcFEFL2P":"title","uWC2gSvUo":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framery5JjNq3iI: React.ComponentType<Props> = withCSS(Component, css, "framer-WTZbK") as typeof Component;
export default Framery5JjNq3iI;

Framery5JjNq3iI.displayName = "Breadcrumb";

Framery5JjNq3iI.defaultProps = {height: 18, width: 37.5};

addPropertyControls(Framery5JjNq3iI, {variant: {options: ["YmvBf5PJG", "iPx6mhDCg"], optionTitles: ["Inactive", "Active"], title: "Variant", type: ControlType.Enum}, PpcFEFL2P: {defaultValue: "Home", displayTextArea: false, title: "Title", type: ControlType.String}, uWC2gSvUo: {title: "Link", type: ControlType.Link}} as any)

addFonts(Framery5JjNq3iI, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})